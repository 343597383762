import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

export default () => (
  <Layout>
    <SEO pageTitle="Newsletter signup submitted" />
    <Header pageTitle="Thanks for signing up" bgImage="backgrounds/header.jpg" />
    <section className="intro-text">
      <div className="container">
        <p>Thanks. You're now signed up to the Birds of Beauty email newsletter, keeping you updated with the latest news, offers and discounts.</p>
        <p><a href="/A-guide-to-the-top-9-eyebrow-treatments.pdf">A guide to the top 9 eyebrow treatments (PDF)</a>.</p>
      </div>
    </section>
  </Layout>
)